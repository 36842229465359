// extracted by mini-css-extract-plugin
export var blogPostDescriptionField = "BlogPostCategoryEditForm__blogPostDescriptionField__BPsHZ";
export var buttonEditContainer = "BlogPostCategoryEditForm__buttonEditContainer__mZZQU";
export var column = "BlogPostCategoryEditForm__column__W4gJd";
export var editor = "BlogPostCategoryEditForm__editor__emxtQ";
export var fieldInstructions = "BlogPostCategoryEditForm__fieldInstructions__DO8Au";
export var flexContainer = "BlogPostCategoryEditForm__flexContainer__WCxi4";
export var grid = "BlogPostCategoryEditForm__grid__uBORL";
export var iconDelete = "BlogPostCategoryEditForm__iconDelete__H2Doo";
export var iconView = "BlogPostCategoryEditForm__iconView__zfrcI";
export var labelContainer = "BlogPostCategoryEditForm__labelContainer__TdiT9";
export var row = "BlogPostCategoryEditForm__row__HAVRQ";